import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { CourierManagementPresenter } from "../presenters/CourierManagementPresenter";
import {
  ApiRequestCourierManagementCreate,
  ApiRequestCourierManagementList
} from "@/data/payload/api/CourierManagementApiRequest";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "courier-management"
})
class CourierManagementStore extends VuexModule {
  @Action
  public getList(params: ApiRequestCourierManagementList) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.getList(params);
  }

  @Action
  public downloadFile(params: ApiRequestCourierManagementList) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.downloadFile(params);
  }

  @Action
  public getDetail(id: number) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.getDetail(id);
  }
  @Action
  public createCourier(payload: ApiRequestCourierManagementCreate) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.createCourier(payload);
  }
  @Action
  public editCourier(payload: ApiRequestCourierManagementCreate) {
    const presenter = container.resolve(CourierManagementPresenter);
    return presenter.editCourier(payload);
  }
}

export const CourierManagementController = getModule(CourierManagementStore);
