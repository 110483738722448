import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { CourierManagementDetail } from "@/domain/entities/CourierManagement";
import { OptionsClass, QueryParamsEntities } from "@/domain/entities/MainApp";

export class ApiRequestCourierManagementList {
  page = 1;
  perPage = 20;
  q = "";
  courierStatus = new OptionsClass();
  driverType = new OptionsClass();
  constructor(fields?: Partial<ApiRequestCourierManagementList>) {
    Object.assign(this, fields);
  }
  toQueryString(): string {
    return new QueryParamsEntities(
      ConvertObjectCamelToSnakeCase({
        ...this,
        courierStatus: this.courierStatus.value,
        driverType: this.driverType.value,
      })
    ).queryString;
  }
}

export class ApiRequestCourierManagementCreate {
  payload: CourierManagementDetail = new CourierManagementDetail()

  constructor(fields?: Partial<ApiRequestCourierManagementCreate>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase({
      ...this.payload,
      ...this.payload.bank,
      partnerExternalCode: this.payload.partner.partnerExternalCode,
    }));
  }
}

