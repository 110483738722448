
import {
  checkRolePermission,
  formatInputNumberOnlyV2,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { PosController } from "@/app/ui/controllers/PosController";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";
import { debounce } from "lodash";
import { Options, Vue } from "vue-class-component";
import { CourierManagementController } from "@/app/ui/controllers/CourierManagementController";
import { ApiRequestCourierManagementCreate } from "@/data/payload/api/CourierManagementApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  ErrorMessageEntities,
  OptionsClass,
  SubData
} from "@/domain/entities/MainApp";
import { CourierManagementDetail } from "@/domain/entities/CourierManagement";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { COURIER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import validatePlateNumber from "@/app/infrastructures/misc/common-library/ValidatePlateNumber";
import { ValidateMsisdn } from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

@Options({
  watch: {
    $route(to, from) {
      if (this.isDetail || this.isEdit) {
        this.fetchDetail();
      }
      this.mappingAccountType();
    }
  }
})
export default class FormCourier extends Vue {
  get isEditable(): boolean {
    let haveAccess = false;
    switch (this.accountType) {
      case "pos":
      case "console":
      case "sub-console":
        haveAccess =
          FlagsMedusa.config_modify_courier_enable.isEnabled() &&
          !this.dataCourier.announcementCourierType.match(/kvp/gi);
        break;

      default:
        haveAccess = true;
        break;
    }
    return haveAccess && checkRolePermission(COURIER_MANAGEMENT.EDIT);
  }

  mounted() {
    if (this.isDetail || this.isEdit) {
      this.fetchDetail();
    }
    this.mappingAccountType();
  }

  mappingAccountType() {
    switch (this.accountType) {
      case "pos":
        this.dataCourier.courierType = "POS";
        this.dataCourier.announcementCourierType = "POS";
        this.dataCourier.vehicleType = "MOTORCYCLE";
        this.dataCourier.partner = new ClientPartnerOptions({
          partnerExternalCode:
            AccountController.accountData.account_type_detail
              .partnerExternalCode,
          name: AccountController.accountData.account_type_detail.company_name
        });
        this.partnerData = {
          label: "Nama POS",
          placeholder: "Masukkan nama pos",
          isDisabled: true
        };
        break;
      case "console":
      case "sub-console":
        this.dataCourier.courierType = "TRUCK";
        this.dataCourier.announcementCourierType = "TRUCK";
        this.dataCourier.vehicleType = "TRUCK";
        this.dataCourier.partner = new ClientPartnerOptions({
          partnerExternalCode:
            AccountController.accountData.account_type_detail
              .partnerExternalCode,
          name: AccountController.accountData.account_type_detail.company_name
        });
        this.partnerData = {
          label: "Nama Consolidator",
          placeholder: "",
          isDisabled: true
        };
        break;
      default:
        this.partnerData = {
          label: "Nama Konsol/POS",
          placeholder: "Masukkan nama konsol/pos",
          isDisabled: this.isEdit
        };
        break;
    }
  }

  get id(): string {
    return String(this.$route.params?.id);
  }
  get isDetail(): boolean {
    return !!(this.$route.name as string)?.match(/detail/i);
  }
  get isEdit() {
    return !!(this.$route.name as string)?.match(/edit/i);
  }
  get title(): string {
    if (this.isDetail) {
      return String(this.$route.params?.id);
    } else if (this.isEdit) {
      return "Edit Kurir";
    }
    return "Tambah Kurir";
  }

  detailEntities = new ResponsePayloadV2();
  dataCourier = new CourierManagementDetail();
  async fetchDetail(): Promise<void> {
    try {
      this.detailEntities.loading = true;
      this.detailEntities = await CourierManagementController.getDetail(
        +this.id
      );
      this.dataCourier = this.detailEntities.data;
      this.courierStatusToggle = this.detailEntities.data.courierStatus.match(
        /^active/gi
      );
      if (
        this.isEdit &&
        this.dataCourier.announcementCourierType.match(/kvp/i) &&
        this.accountType !== "internal"
      ) {
        this.goToCourierDetail();
      }
    } catch (error) {
      const errorEntities: ErrorMessageEntities = parsingErrorResponse(error);
      this.detailEntities.message = errorEntities.type;
    } finally {
      this.detailEntities.loading = false;
    }
  }
  get subData(): SubData {
    if (this.isDetail) {
      return new SubData({
        status: this.dataCourier.courierStatus,
        isEditable: this.isEditable,
        goEdit: () => this.$router.push(`/courier-management/${this.id}/edit`)
      });
    }
    return new SubData({
      status: this.dataCourier.courierStatus
    });
  }

  partnerType = "";
  isOpenCourierType = false;
  isOpenAnnouncementCourierType = false;
  isOpenVehicleType = false;
  isOpenBank = false;
  courierStatusToggle = false;
  courierTypeList = [
    new OptionsClass({
      name: "Pilih tipe kurir",
      value: ""
    }),
    new OptionsClass({
      name: "POS",
      value: "POS"
    }),
    new OptionsClass({
      name: "KVP",
      value: "KVP"
    }),
    new OptionsClass({
      name: "Truck",
      value: "TRUCK"
    })
  ];

  announcementCourierTypeList = [
    new OptionsClass({
      name: "Pilih announcement tipe kurir",
      value: ""
    }),
    new OptionsClass({
      name: "POS",
      value: "POS"
    }),
    new OptionsClass({
      name: "KVP",
      value: "KVP"
    }),
    new OptionsClass({
      name: "Truck",
      value: "TRUCK"
    })
  ];

  vehicleTypeList = [
    new OptionsClass({
      name: "Pilih tipe kendaraan",
      value: ""
    }),
    new OptionsClass({
      name: "Sepeda Motor",
      value: "MOTORCYCLE"
    }),
    new OptionsClass({
      name: "Truck",
      value: "TRUCK"
    })
  ];

  bankList = [
    new OptionsClass({
      name: "Pilih Bank",
      value: ""
    }),
    new OptionsClass({
      name: "BCA",
      value: "BCA"
    }),
    new OptionsClass({
      name: "Maybank",
      value: "MAYBANK"
    }),
    new OptionsClass({
      name: "Mandiri",
      value: "MANDIRI"
    }),
    new OptionsClass({
      name: "BRI",
      value: "BRI"
    })
  ];

  error = {
    phoneNumber: false,
    vehiclePlate: false,
    courierType: false,
    bankAccount: false
  };

  errorCaption = {
    phoneNumber: "Nomor telepon tidak valid, mohon ulangi kembali",
    vehiclePlate: "Plat nomor tidak valid, mohon ulangi kembali",
    bankAccount: "Nomor bank tidak valid, mohon ulangi kembali",
    courierType: "Tipe kurir wajib diisi"
  };

  partnerData = {
    label: "",
    placeholder: "",
    isDisabled: false
  };

  showModal = false;
  isModalConfirmation = false;

  get dialogData() {
    if (this.isModalConfirmation) {
      return {
        title: "Simpan Detail Kurir?",
        message: "Pastikan detail kurir yang Anda masukkan telah sesuai",
        image: "badge-confirmation-general",
        textSuccess: "Simpan",
        textCancel: "Kembali",
        onSuccess: this.onEditCourier,
        onClose: this.onCloseModal
      };
    } else if (this.isEdit) {
      return {
        title: "Data Kurir Berhasil Diubah",
        message: "Silakan cek detail kurir yang telah Anda ubah",
        image: "badge-success",
        textSuccess: "Cek Kurir",
        onSuccess: this.goToCourierDetail
      };
    }
    return {
      title: "Berhasil Menambahkan Kurir",
      message: "Silakan cek detail kurir yang telah Anda tambahkan",
      image: "badge-success",
      textSuccess: "Cek Kurir",
      onSuccess: this.goToCourierList
    };
  }

  get accountType() {
    if (AccountController.accountData.account_type === "partner") {
      return AccountController.accountData.account_type_detail.type;
    }
    return AccountController.accountData.account_type;
  }

  get isLoadingPartnerList() {
    return PosController.isLoading;
  }

  get isTruck() {
    return this.dataCourier.courierType.match(/^truck/gi);
  }

  get isFormValid() {
    return (
      !!(
        this.dataCourier.fullname &&
        this.dataCourier.phoneNumber &&
        this.dataCourier.courierType &&
        this.dataCourier.announcementCourierType &&
        this.dataCourier.vehicleType &&
        this.dataCourier.vehiclePlate &&
        this.dataCourier.partner.partnerExternalCode &&
        ((this.dataCourier.bank.bankId &&
          this.dataCourier.bank.bankAccount &&
          this.dataCourier.bank.bankAccountName) ||
          this.isTruck)
      ) &&
      !(
        this.error.phoneNumber ||
        this.error.bankAccount ||
        this.error.courierType ||
        this.error.vehiclePlate
      )
    );
  }

  onUpdateStatusCourier(value: boolean) {
    this.dataCourier.courierStatus = value ? "active" : "inactive";
  }

  onBack() {
    this.isEdit ? this.goToCourierDetail() : this.goToCourierList();
  }

  goToCourierList() {
    this.$router.push("/courier-management");
  }

  goToCourierDetail() {
    this.$router.push(`/courier-management/${this.id}`);
    this.onCloseModal();
    this.fetchDetail();
  }

  onOpenSelectCourierType() {
    this.isOpenCourierType = true;
  }

  onCloseSelectCourierType() {
    this.isOpenCourierType = false;
  }

  onSelectCourierType(key: string, value: string) {
    switch (value) {
      case "POS":
      case "KVP":
        this.dataCourier.vehicleType = "MOTORCYCLE";
        this.vehicleTypeList = [
          new OptionsClass({
            name: "Pilih tipe kendaraan",
            value: ""
          }),
          new OptionsClass({
            name: "Sepeda Motor",
            value: "MOTORCYCLE"
          })
        ];
        break;
      case "TRUCK":
        this.vehicleTypeList = [
          new OptionsClass({
            name: "Pilih tipe kendaraan",
            value: ""
          }),
          new OptionsClass({
            name: "Truck",
            value: "TRUCK"
          })
        ];
        this.dataCourier.vehicleType = "TRUCK";
        break;
      default:
        this.dataCourier.vehicleType = "";
        this.vehicleTypeList = [
          new OptionsClass({
            name: "Pilih tipe kendaraan",
            value: ""
          }),
          new OptionsClass({
            name: "Sepeda Motor",
            value: "MOTORCYCLE"
          }),
          new OptionsClass({
            name: "Truck",
            value: "TRUCK"
          })
        ];
        break;
    }
    if (value === "POS") {
      this.partnerType = "pos";
    } else {
      this.partnerType = "console";
    }
    this.error.courierType = !value;
    this.dataCourier.courierType = value;
  }

  onOpenSelectAnnouncementCourierType() {
    this.isOpenAnnouncementCourierType = true;
  }

  onCloseSelectAnnouncementCourierType() {
    this.isOpenAnnouncementCourierType = false;
  }

  onSelectAnnouncementCourierType(key: string, value: string) {
    this.dataCourier.announcementCourierType = value;
  }

  onOpenSelectVehicleType() {
    this.isOpenVehicleType = true;
  }

  onCloseSelectVehicleType() {
    this.isOpenVehicleType = false;
  }

  onSelectVehicleType(key: string, value: string) {
    this.dataCourier.vehicleType = value;
  }

  onOpenSelectBank() {
    this.isOpenBank = true;
  }

  onCloseSelectBank() {
    this.isOpenBank = false;
  }

  onSelectBank(key: string, value: string) {
    this.dataCourier.bank.bankId = value;
  }

  partnerList: ClientPartnerOptions[] = [];
  filterPartnerList = debounce(async (search: string) => {
    const response = await PartnerController.fetchListPartnerOptions({
      page: 1,
      limit: 10,
      search: search,
      type: this.partnerType
    });
    this.partnerList = response;
  }, 250);

  formatPlateNumber(value: string) {
    return value.toUpperCase().replace(" ", "");
  }

  formatNumber(value: string) {
    return formatInputNumberOnlyV2(value);
  }

  validatePhone(value: string) {
    this.error.phoneNumber = ValidateMsisdn(value).error;
  }

  validateCourierType() {
    this.error.courierType = !this.dataCourier.courierType;
    if (this.dataCourier.courierType) {
      this.filterPartnerList("");
    }
  }

  validateVehicleNumber() {
    this.error.vehiclePlate = validatePlateNumber(
      this.dataCourier.vehiclePlate
    );
  }

  validateBankAccount() {
    this.error.bankAccount = !/^.{10,16}$/.test(
      this.dataCourier.bank.bankAccount
    );
  }

  onSaveCourier() {
    if (this.isEdit) {
      this.showModal = true;
      this.isModalConfirmation = true;
      return;
    }
    this.onCreateCourier();
  }

  onCloseModal() {
    this.showModal = false;
  }

  async onCreateCourier() {
    this.onCloseModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      await CourierManagementController.createCourier(
        new ApiRequestCourierManagementCreate({
          payload: new CourierManagementDetail({
            ...this.dataCourier,
            phoneNumber: `+62${this.dataCourier.phoneNumber}`
          })
        })
      );
      this.showModal = true;
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Gagal Menambahkan Kurir",
          this.onCreateCourier
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  async onEditCourier() {
    this.isModalConfirmation = false;
    this.onCloseModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      await CourierManagementController.editCourier(
        new ApiRequestCourierManagementCreate({
          payload: new CourierManagementDetail(this.dataCourier)
        })
      );
      this.showModal = true;
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mengubah Kurir", this.onEditCourier)
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  goToWhatsapp() {
    if (this.dataCourier.phoneNumber) {
      const phoneFormat = this.dataCourier.phoneNumber.replace(/\+/g, "");
      window.open(
        `https://api.whatsapp.com/send?phone=${phoneFormat}`,
        "_blank"
      );
    }
  }
}
